import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import CookieConsent from 'react-cookie-consent';
import Routes from './route';
import 'font-awesome/css/font-awesome.min.css';
import 'react-tippy/dist/tippy.css';

const App = ({ history }) => {
  useEffect(() => {
    ReactGA.initialize('UA-204552080-1');
  });

  return (
    <>
      <Routes history={history} />
      <CookieConsent
        style={{
          background: 'rgba(165, 91, 103, 0.9)',
          color: 'white',
          display: 'flex',
          flex: 'wrap',
          padding: '9px 10px',
          cursor: 'pointer',
        }}
        buttonStyle={{
          background: 'rgb(13 26 47)',
          color: 'rgb(255, 255, 255)',
          margin: '15px',
          padding: '10px 30px',
          flex: '0 0 auto',
        }}
        overlay
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </>
  );
};
export default App;
