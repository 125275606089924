import React, { Suspense } from 'react';
import { Switch, Route, Redirect, Router } from 'react-router-dom';
import ReactGA from 'react-ga';

import { appRouter } from './SharedComponents/Routes/appRouter';

const Routes = ({ history }) => (
  <>
    <Router history={history}>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          {appRouter.map((prop, key) => {
            if (prop.pro) {
              return null;
            }
            if (prop.redirect) {
              return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
            }
            return <Route path={prop.path} component={prop.component} key={key} />;
          })}
        </Switch>
      </Suspense>
    </Router>
    {history.listen((location) => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    })}
  </>
);

export default Routes;
