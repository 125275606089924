import uk from '../../../assets/englishLanguage.json';
import gl from '../../../assets/germanLanguage.json';
import { setCookie } from '../../Helpers/storageHelper';
import { TOGGLE_GUEST_MODAL, SET_BUSINESS_PROFILE, SET_BUSINESS_PROFILE_NULL, SET_LANGUAGE, SET_MASTER_DATA, TOGGLE_SCREEN_VIEW } from './actionTypes';

export const initialState = {
  error: null,
  loading: false,
  productCategories: [],
  postType: [],
  relationships: [],
  contactTypes: [],
  overallCount: [],
  businessId: null,
  showGuestModal: false,
  isWebView: true,
};

const masterData = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_MASTER_DATA:
      state = {
        ...state,
        ...payload,
      };
      break;

    case SET_LANGUAGE:
      state = {
        ...state,
        language: payload,
        lg: {
          ...(payload == 'German' ? gl : uk),
        },
      };
      if (process.env.REACT_APP_IS_LOCAL || process.env.NEXT_PUBLIC_IS_LOCAL) setCookie('language', payload);
      break;
    case SET_BUSINESS_PROFILE:
      state = {
        ...state,
        businessId: payload,
      };
      break;
    case SET_BUSINESS_PROFILE_NULL:
      state = {
        ...state,
        businessId: null,
      };
      break;
    case TOGGLE_GUEST_MODAL:
      state = { ...state, showGuestModal: !state.showGuestModal };
      break;
    case TOGGLE_SCREEN_VIEW:
      state = { ...state, isWebView: !state.isWebView };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default masterData;
