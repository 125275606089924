import SET_OVERALL_COUNT from './actionTypes';

export const initialState = {
  overallCount: [],
};

const overallCount = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_OVERALL_COUNT:
      state = {
        ...state,
        overallCount: payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default overallCount;
