import React from 'react';

const Publiclayout = React.lazy(() => import('../Layout/publicLayout'));
const LandingPage = React.lazy(() => import('../../Modules/LandingPage/landingPage'));
const Adminlayout = React.lazy(() => import('../Layout/adminLayout'));
const RedirectToNext = React.lazy(() => import('../../Modules/Memorial/redirectToNext'));

export const appRouter = [
  {
    path: '/public',
    title: 'Public',
    exact: true,
    component: Publiclayout,
  },

  {
    path: '/admin',
    title: 'Admin',
    exact: true,
    component: Adminlayout,
  },
  {
    path: '/pages/view-memorial/:slug?/:id',
    title: 'Memorial',
    exact: true,
    component: RedirectToNext,
  },
  {
    redirect: true,
    path: '/',
    pathTo: '/public/landing-page',
    title: 'Landing Page',
    exact: true,
    component: LandingPage,
  },
];

export default appRouter;
