// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import { compose, createStore } from 'redux';

import uk from '../../assets/englishLanguage.json';
import gl from '../../assets/germanLanguage.json';
import { getCookie } from '../Helpers/storageHelper';
import { initialState } from './AuthDataStore/reducer';
import { initialState as initialMasterState } from './MasterDataStore/reducer';
import rootReducer from './reducer';

export const history = typeof window !== 'undefined' && createBrowserHistory();

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const loadFromLocalStorage = () => {
  try {
    let serializedState = localStorage.getItem('userData');
    let getLanguage = localStorage.getItem('language');

    if (process.env.REACT_APP_IS_LOCAL || process.env.NEXT_PUBLIC_IS_LOCAL) {
      if (!serializedState || process.env.NEXT_PUBLIC_IS_LOCAL) {
        let cookieData = getCookie('userData');
        if (cookieData && cookieData != 'undefined' && cookieData != serializedState) serializedState = cookieData;
        else if (!cookieData) serializedState = null;
      }
      if (!getLanguage || process.env.NEXT_PUBLIC_IS_LOCAL) {
        let cookieLan = getCookie('language');
        if (cookieLan && cookieLan != 'undefined' && cookieLan != getLanguage) getLanguage = cookieLan;
        else if (!cookieLan) getLanguage = null;
      }
    }
    let languageSetUp = {
      MasterData: {
        ...initialMasterState,
        language: getLanguage || 'German',
        lg: getLanguage === 'Uk' ? uk : gl,
      },
    };
    document.body.classList.add(getLanguage || 'German');
    if (serializedState === null) return { ...languageSetUp };
    return {
      AuthData: {
        ...initialState,
        userData: JSON.parse(serializedState),
      },
      ...languageSetUp,
    };
  } catch (e) {
    return undefined;
  }
};

const store = createStore(rootReducer, loadFromLocalStorage(), composeEnhancers());
export default store;
