import axios from 'axios';

import store, { history } from '../../Store/store';
import { onSetUser, onLoginError } from '../../Store/AuthDataStore/actions';
import { ToastNotification } from '../ToastNotifications/toastNotification';

const apiRoot = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : process.env.NEXT_PUBLIC_API_URL;
const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const axiosInstance = axios.create({
  baseURL: apiRoot,
});

const requestHandler = (request) => {
  request.headers.Platform = 'Web';
  request.headers.Language = store.getState().MasterData.language;
  let authState = store.getState().AuthData;
  if (authState.userData) {
    let { accessToken, refreshToken } = authState.userData;
    if (accessToken) request.headers.Authorization = `Bearer ${accessToken}`;
    if (refreshToken) request.headers.RefreshToken = refreshToken;
  }
  return request;
};

let toast = new ToastNotification();

let onResponseFailed = (error) => {
  let lg = store.getState().MasterData.lg;
  let location = window.location.pathname;
  if (location !== '/public/sign_in' && location !== '/public/register' && [401].some((item) => (error.response ? item === error.response?.data.statusCode : false))) {
    let message = '';
    let type = '';
    if (error.response?.data) {
      let {
        data: { message: msg, type: errType },
      } = error.response;
      message = msg;
      type = errType;
    }
    if (!error.request.responseURL.includes('/account/')) {
      if (type == 'session') store.dispatch(onLoginError(message));
      history.push({
        pathname: '/public/logout',
        state: { redirect: type == 'session' ? '/public/sign_in' : '' },
      });
    }
    return Promise.reject(new Error(message || lg.unAuthorised));
  }
  if (error.response && error.response.status && error.response.data) {
    let err = {
      status: error.response.status,
      statusText: error.response.statusText,
      message: error.response.data?.message ? error.response.data.message : lg.somethingWentWrong,
    };
    return Promise.reject(err);
  }
  return Promise.reject(new Error(error.response?.data.message ? error.response.data.message : lg.somethingWentWrong));
};

const onResponseSuccess = (response) => {
  let location = window.location.pathname;
  let authState = store.getState().AuthData;
  let accessToken = '';
  let refreshToken = '';
  if (authState.userData) ({ accessToken, refreshToken } = authState.userData);
  let { accesstoken: jwtToken, refreshtoken: refresh, sessionexpired } = response.headers;
  if (sessionexpired) {
    localStorage.removeItem('userData');
    store.dispatch(onSetUser(null));
    store.dispatch(onLoginError(sessionexpired));
    toast.show('session_expiry', 'ERROR', sessionexpired);
    if (location.includes('public/my-account')) history.push('/public/sign_in');
  }
  if (jwtToken != null && refresh !== null && !sessionexpired) {
    if (accessToken !== jwtToken && refreshToken !== refresh) {
      let updateData = {
        ...authState.userData,
        accessToken: jwtToken,
        refreshToken: refresh,
      };
      localStorage.setItem('userData', JSON.stringify(updateData));
      store.dispatch(onSetUser(updateData));
    }
  }
  return response;
};

class HttpService {
  constructor() {
    if (axiosInstance.interceptors.request.handlers.length === 0) {
      axiosInstance.interceptors.request.use((request) => requestHandler(request));
      axiosInstance.interceptors.response.use(
        (response) => onResponseSuccess(response),
        (error) => onResponseFailed(error),
      );
    }
  }

  getWithHeader = (path, header) => {
    if (path) return axiosInstance.get(apiRoot + path, header, config);
    return null;
  };

  get = (path, model) => {
    if (path) return axiosInstance.get(apiRoot + path + (path.includes('?') ? '&date=' : '?date=') + new Date(), { ...config, params: model });
    return null;
  };

  post = (path, model) => {
    if (path) return axiosInstance.post(apiRoot + path, model, config);
    return null;
  };

  put = (path, model) => {
    if (path) return axiosInstance.put(apiRoot + path, model, config);
    return null;
  };

  remove = (path, model) => {
    if (path) return axiosInstance.delete(apiRoot + path, { data: model }, config);
    return null;
  };
}

export const http = new HttpService();
export const { getWithHeader } = new HttpService();
export const { get } = new HttpService();
export const { post } = new HttpService();
export const { put } = new HttpService();
export const { remove } = new HttpService();
