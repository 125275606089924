import { post } from '../Libraries/Ajax/httpService';
import { ToastNotification } from '../Libraries/ToastNotifications/toastNotification';
import store, { history } from '../Store/store';
import { onLoginError, onRegisterError, onSetUser, setLoadingActive } from '../Store/AuthDataStore/actions';
import { getNextUrl, setExpiredCookie } from './storageHelper';

const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID ? process.env.REACT_APP_FACEBOOK_APP_ID : process.env.NEXT_PUBLIC_FACEBOOK_APP_ID;

const onInitFacebookSdk = () =>
  new Promise((resolve) => {
    // wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: facebookAppId,
        cookie: true,
        xfbml: true,
        version: 'v8.0',
      });

      // auto authenticate with the api if already logged in with facebook
      window.FB.getLoginStatus(({ authResponse }) => {
        if (authResponse) {
          window.FB.api('/me/permissions', 'delete', null, () => window.FB.logout());
          resolve();
        } else {
          resolve();
        }
      });
    };

    // load facebook sdk script
    ((d, s, id) => {
      let js;
      let fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  });

const setReduxState = (message, isRegister) => {
  if (isRegister) store.dispatch(onRegisterError(message));
  else store.dispatch(onLoginError(message));
};
const onApiAuthenticate = (accessToken) => {
  let apiUrl = 'account/facebook';
  if (window.location.pathname.includes('register')) {
    apiUrl = 'account/facebook-sign-up';
  }
  return post(apiUrl, { accessToken });
};

const onLogout = () => {
  // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
  if (window.FB && window.FB.getAccessToken()) {
    window.FB.api('/me/permissions', 'delete', null, () => window.FB.logout());
  }
};
let toast = new ToastNotification();

const onLogin = async (isCallback) =>
  new Promise(async (resolveCallback) => {
    let { authResponse } = await new Promise((resolve) => {
      window.FB.login(
        (response) => {
          resolve(response);
        },
        { scope: 'email' },
      );
    });
    if (!authResponse) return;

    window.FB.api('/me', { fields: ['email', 'name'] }, (res) => {
      let lg = store.getState().MasterData.lg;

      let location = window.location.pathname;
      let isRegisterPage = location.includes('register');
      if (res.email) {
        store.dispatch(setLoadingActive());
        onApiAuthenticate(authResponse.accessToken)
          .then((authRes) => {
            if (authRes.data) {
              if (authRes.data?.accessToken && authRes.data?.refreshToken) {
                localStorage.setItem('userData', JSON.stringify(authRes.data));
                store.dispatch(onSetUser(authRes.data));
                if (isRegisterPage) {
                  toast.show('register_success', 'SUCCESS', lg.registerSuccess);
                }
                onLogout();
                let nextUrl = getNextUrl();
                if (nextUrl) {
                  if (nextUrl.includes('pages/view-memorial')) window.location = `${process.env.REACT_APP_NEXT_URL}${nextUrl}`;
                  else history.push(nextUrl);
                  localStorage.removeItem('nextUrl');
                  if (process.env.REACT_APP_IS_LOCAL) setExpiredCookie('nextUrl');
                } else if (isCallback) {
                  resolveCallback({ success: true });
                } else history.push('/public/landing-page');
              } else if (isCallback) {
                resolveCallback({ success: false, message: lg.somethingWrong });
              } else setReduxState(authRes.data?.message ? authRes.data?.message : lg.somethingWrong, isRegisterPage);
            }
          })
          .catch((error) => {
            onLogout();
            if (isCallback) {
              resolveCallback({ success: false, message: error.message });
            } else setReduxState(error.message, isRegisterPage);
          });
      } else if (isCallback) resolveCallback({ success: false, message: lg.noEmail });
      else setReduxState(lg.noEmail, isRegisterPage);
    });
  });

const facebookService = {
  onLogin,
  onLogout,
  onInitFacebookSdk,
};
export default facebookService;
