import { API_ERROR, LOGIN_ERROR, LOGIN_SUCCESS, LOGOUT_USER, REGISTER_ERROR, REGISTER_SUCCESS, SET_LOADING_ACTIVE, SET_LOADING_INACTIVE, SET_USER } from './actionTypes';

export const onSetUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const onLogoutUser = () => ({
  type: LOGOUT_USER,
  payload: {},
});

export const onApiError = (error) => ({
  type: API_ERROR,
  payload: error,
});
export const onLoginError = (error) => ({
  type: LOGIN_ERROR,
  payload: error,
});
export const onLoginSuccess = (data) => ({
  type: LOGIN_SUCCESS,
  payload: data,
});
export const onRegisterError = (error) => ({
  type: REGISTER_ERROR,
  payload: error,
});
export const onRegisterSuccess = (data) => ({
  type: REGISTER_SUCCESS,
  payload: data,
});
export const setLoadingActive = () => ({
  type: SET_LOADING_ACTIVE,
  payload: null,
});
export const setLoadingInactive = () => ({
  type: SET_LOADING_INACTIVE,
  payload: null,
});
