export function setCookie(cookieName, value) {
  let expDate = new Date();
  expDate.setDate(expDate.getDate() + 30);
  document.cookie = `${cookieName}=${value};path=/;expires=${expDate.toGMTString()}`;
}
export function setExpiredCookie(cookieName, value) {
  let expDate = new Date();
  expDate.setDate(expDate.getDate() - 1);
  document.cookie = `${cookieName}=${value};path=/;expires=${expDate.toGMTString()}`;
}
export function getCookie(name) {
  let dc = document.cookie;
  let prefix = `${name}=`;
  let begin = dc.indexOf(`; ${prefix}`);
  if (begin == -1) {
    begin = dc.indexOf(prefix);
    if (begin != 0) return null;
  } else {
    begin += 2;
  }
  let end = document.cookie.indexOf(';', begin);
  if (end == -1) {
    end = dc.length;
  }
  return unescape(dc.substring(begin + prefix.length, end));
}
export function setNextUrl(url) {
  localStorage.setItem('nextUrl', url);
  if (process.env.NEXT_PUBLIC_IS_LOCAL) setCookie('nextUrl', url);
}
export function getNextUrl() {
  let url = localStorage.getItem('nextUrl');
  if (process.env.REACT_APP_IS_LOCAL && !url) url = getCookie('nextUrl', url);
  return url;
}
