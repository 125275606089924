import { API_ERROR, LOGIN_ERROR, LOGIN_SUCCESS, LOGOUT_USER, REGISTER_ERROR, REGISTER_SUCCESS, SET_LOADING_ACTIVE, SET_LOADING_INACTIVE, SET_USER } from './actionTypes';
import { setCookie, setExpiredCookie } from '../../Helpers/storageHelper';

export const initialState = {
  error: null,
  loading: false,
  userData: null,
  loginErrorState: null,
  loginSuccessState: null,
  registerErrorState: null,
  guestUserData: null,
};

const authData = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER:
      state = {
        ...state,
        userData: payload,
        loading: false,
        loginErrorState: null,
        registerErrorState: null,
        loginSuccessState: null,
      };
      if (process.env.REACT_APP_IS_LOCAL || process.env.NEXT_PUBLIC_IS_LOCAL) setCookie('userData', JSON.stringify(payload));
      break;
    case LOGOUT_USER:
      state = { ...state, userData: null };
      if (process.env.REACT_APP_IS_LOCAL || process.env.NEXT_PUBLIC_IS_LOCAL) setExpiredCookie('userData', '');
      break;
    case API_ERROR:
      state = { ...state, error: payload, loading: false };
      break;
    case LOGIN_ERROR:
      state = { ...state, loginErrorState: payload, loading: false };
      break;
    case LOGIN_SUCCESS:
      state = { ...state, loginSuccessState: payload, loading: false };
      break;
    case REGISTER_ERROR:
      state = { ...state, registerErrorState: payload, loading: false };
      break;
    case REGISTER_SUCCESS:
      state = { ...state, registerErrorState: null, loading: false };
      break;
    case SET_LOADING_INACTIVE:
      state = { ...state, loading: false };
      break;
    case SET_LOADING_ACTIVE:
      state = { ...state, loading: true };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default authData;
