import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure({
  autoClose: 3000,
  draggable: false,
  pauseOnHover: false,
  hideProgressBar: true,
  closeButton: false,
});
const CLOSE_AFTER = 4500;
export class ToastNotification {
  status = {
    success: 'SUCCESS',
    error: 'ERROR',
    warning: 'WARNING',
    info: 'INFO',
  };

  toastConfig = {
    autoClose: CLOSE_AFTER,
    pauseOnHover: false,
    hideProgressBar: true,
    closeButton: false,
    pauseOnFocusLoss: false,
  };

  show(msgId, msgStatus, message, isAutoClose = CLOSE_AFTER) {
    this.toastConfig.toastId = msgId;
    if (toast.isActive(msgId) || (toast.isActive('internet_failed') && !(msgId === 'internet_active'))) {
      return;
    }
    this.toastConfig.autoClose = isAutoClose;
    if (!isAutoClose) {
      this.toastConfig.closeOnClick = false;
    }
    switch (msgStatus) {
      case this.status.success:
        toast.success(message, this.toastConfig);
        break;
      case this.status.error:
        toast.error(message, this.toastConfig);
        break;
      case this.status.warning:
        toast.warn(message, this.toastConfig);
        break;
      case this.status.info:
        toast.info(message, this.toastConfig);
        break;
      default:
    }
  }

  dismiss = (msgId) => {
    toast.dismiss(msgId);
  };
}
export default ToastNotification;
