import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import App from './App/App';
import store, { history } from './App/Store/store';
import './assets/scss/main.scss';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/filter';
import 'core-js/features/array/map';
import 'core-js/features/array/includes';
import 'core-js/features/array/index-of';
import 'core-js/features/object/assign';
import 'core-js/features/promise/finally';
import 'core-js/features/string/includes';
import 'core-js/features/string/starts-with';
import 'core-js/features/object/entries';
import 'core-js/features/array/for-each';
import facebookService from './App/Helpers/facebookInit';

facebookService.onInitFacebookSdk();

require('dotenv').config();

render(
  <Provider store={store}>
    <App history={history} />
  </Provider>,
  document.getElementById('root'),
);
